import * as React from "react";
import Helmet from "react-helmet";
import { A1, A5, A7 } from "../components/fonts";
import MinehubBanner from "../components/minehub_banner";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../components/container";
import PropTypes from "prop-types";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Button from "../components/button";

const BlogEntry = (props) => {
  return (
    <div className="px-3">
      {props.link.length ? (
        <a href={`${props.link}`}>
          <div className="shadow-sm rounded-md overflow-hidden">
            {props.image}
          </div>
          <A5 className="text-wbSea pt-5 pb-1">{props.date}</A5>
          <A5 className="pb-3 underline text-wbSand hover:text-wbSea transition duration-300">
            {props.title}
          </A5>
        </a>
      ) : (
        <div>
          <div className="shadow-sm rounded-md overflow-hidden">
            {props.image}
          </div>
          <A5 className="text-wbSea pt-5 pb-1">{props.date}</A5>
          <A5 className="pb-3 text-wbSand">{props.title}</A5>
        </div>
      )}
      <div>
        <A7 className="leading-5 pb-10 text-wbSand">{props.text}</A7>
      </div>
    </div>
  );
};

const BlogEntries = [
  <BlogEntry
    key="15"
    date="2022-12-15"
    title="Key Themes: 2023 Commodity Supply Chain"
    text={
      <>
        <div>
          Waybridge has spent the last few months conducting a set of customer
          interviews, attending conferences, and digging in with many of our
          prospects on the issues that they see ahead when it comes to their
          procurement of key raw materials for 2023.
          <br />
          <br />
          The results are in, and if you thought 2020 through 2022 were
          challenging for the commodity supply chain, 2023 may be the most
          difficult year of them all.
          <br />
          <br />
          <a href="/press-releases/key-themes-2023" className="underline">
            Read it now.
          </a>
        </div>
      </>
    }
    link="/press-releases/key-themes-2023"
  />,
  <BlogEntry
    key="14"
    date="2022-09-19"
    title="Going From Reactive to Proactive: Superior Essex Case Study"
    text={
      <>
        <div>
          Read the case study that explains how leading magnet wire producer
          Superior Essex solved their issues with shipment visibility, inventory
          tracking and countless hours spent in Excel and email by adopting the
          Waybridge technology platform.
          <br />
          <br />
          Results such as avoiding plant shutdowns and reducing 90% of Excel
          usage are a clear testiment to the power of digital transformation.
          <br />
          <br />
          <a
            href="/press-releases/superior-essex-case-study"
            className="underline"
          >
            View the case study.
          </a>
        </div>
      </>
    }
    link="/press-releases/superior-essex-case-study"
  />,
  <BlogEntry
    key="13"
    date="2022-05-11"
    title="Waybridge Announces Product Expansion With Superior Essex Manufacturing Plants in European, Asian, and North American Markets"
    text={
      <>
        <div>
          After successfully implementing the Waybridge technology platform with
          two of Superior Essex&apos;s North American plants, we are pleased to
          expand our relationship into their plants in both Europe and Asia.
          <br />
          <br />
          Superior Essex has been an outstanding adopter of Waybridge with
          outstanding product feedback and we can&apos;t wait to help their
          business globally.
          <br />
          <br />
          <a
            href="/press-releases/superior-essex-expansion"
            className="underline"
          >
            Learn more in the full press release.
          </a>
        </div>
      </>
    }
    link="/press-releases/superior-essex-expansion"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/Superior-Essex-Expansion.jpeg"
        alt="Superior Essex Expansion"
      />
    }
  />,
  <BlogEntry
    key="12"
    date="2022-04-20"
    title="Waybridge and the Port of Panama City Team Up to Automate and Digitize Bills of Lading for High Value Break Bulk Cargoes"
    text={
      <>
        <div>
          In order to dramatically improve the resiliency and efficiency of the
          raw materials supply chain, digitizing it as much as possible is a
          must. To help push this forward, Waybridge has taken a big step
          towards that by partnering with the Port of Panama City, one of North
          America&apos;s most critical gateways for the importation of metals.
          <br />
          <br />
          Working together we will bring an Amazon-like tracking experience to
          the North American metals market by automating the digital creation of
          electronic bills of lading benefitting international suppliers and
          domestic consumers.
          <br />
          <br />
          <a
            href="/press-releases/port-of-panama-city-partnership"
            className="underline"
          >
            Learn more in the full press release.
          </a>
        </div>
      </>
    }
    link="/press-releases/port-of-panama-city-partnership"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/Port-of-Panama.png"
        alt="Port of Panama City Expansion"
      />
    }
  />,
  <BlogEntry
    key="11"
    date="2022-04-01"
    title="Andrea Aranguren named a 2022 “Pro to Know” by Supply and Demand Chain"
    text={
      <>
        <div>
          Andrea Aranguren, co-founder and Chief Customer Officer (CCO) of
          Waybridge has been recognized as a winner of Supply and Demand Chain
          Executive&apos;s 2022 Pros to Know Award. <br />
          <br />
          The award highlights outstanding, innovative executives in the supply
          chain space.
          <br />
          <br />
          Needless to say, we think Andrea fits the bill, and we are honored to
          know that our peers think so, too. She is an incredible leader of our
          team, a force of innovation, and we&apos;re extremely proud to see her
          recognized in this way.
          <br />
          <br />
          Bravo, Andrea.
          <br />
          <br />
          And a huge applause for all of the other incredible people named
          alongside her.
        </div>
      </>
    }
    link="/press-releases/andrea-aranguren-pros-to-know-2022"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2022-04-01.jpg"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="9"
    date="2022-03-01"
    title="Kinney Sheth on her career in design and innovating as VP of Design at Waybridge."
    text={
      <>
        <div>
          Kinney Sheth sat down with Diego Segura to speak in an interview about
          her path to becoming VP of Design at Waybridge.
          <br />
          <br />
          She shares learnings from a circuitous career in creativity, and
          shares an inside view of what it&apos;s like working in design and
          product at Waybridge, transforming some of the largest and most
          critical raw materials supply chains in the world.
          <br />
          <br />
          <a href="blog/kinney-interview-02-2022" className="underline">
            Click to read the article.
          </a>
        </div>
      </>
    }
    link="blog/kinney-interview-02-2022"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2022-02-28.png"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="7"
    date="2022-02-07"
    title="Making the supply chain more resilient with security"
    text={
      <>
        <div>
          When researching security frameworks, the alphabet soup that is SOC-2,
          HITRUST, ISO 27001, FedRAMP, CMMC 3PAO, QSAC may leave your head
          spinning.
          <br />
          <br />
          Our CEO Scott Evans breaks it down why digital security is so relevant
          to the raw materials supply chain.
        </div>
      </>
    }
    link="https://www.supplychainbrain.com/blogs/1-think-tank/post/34462-is-your-supply-chain-software-soc-2-compliant-heres-why-it-matters"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/soc-2-scb.png"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="6"
    date="2022-01-07"
    title="Scott Evans appointed Chief Executive Officer of Waybridge"
    text={
      <>
        <div>
          Since co-founding the company in October of 2019, Scott has served as
          the President of Waybridge. He has spent 20+ years in the physical
          commodities space, building the metals trading desk at Goldman Sachs,
          trading at Mitsubishi, and co-founding Concord Resources Limited. He
          will continue to bring those career insights as we aim to transform
          raw materials supply chains.
        </div>
      </>
    }
    link="/press-releases/scott-evans-ceo"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2022-01-07.jpg"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="5"
    date="2021-12-01"
    title="Position forecasts that update like magic."
    text={
      <div>
        We&apos;ve added two breakthrough reports to Waybridge: Stock and Flow,
        and the Monthly Position Report. Consider them a crystal ball into the
        future of your physical position. <br />
        <br />
        It works by aggregating large amounts of information that already exists
        in Waybridge — trade data, shipping forecasts, live tracking ETAs, and
        production plans — and transforming it into forecasts. <br />
        <br />
        <a className="underline" href="/platform">
          Learn more about Waybridge&apos;s short-term and long-term reports.
        </a>
      </div>
    }
    link="/platform"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2021-11-03.png"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="4"
    date="2021-11-19"
    title="Waybridge Week"
    text={
      <div>
        We bridged the gap between our remote team to spend time together in our
        New York office. We are thankful to work with each other and be one team
        from across the globe. <br />
        <br />
        We played games, broke bread, planned for the upcoming year, and
        listened to talks from{" "}
        <a className="underline" href="https://twitter.com/daxdev">
          Dax Devlon-Ross
        </a>{" "}
        and{" "}
        <a
          className="underline"
          href="https://www.linkedin.com/in/donnacosta45"
        >
          Donna Costa
        </a>
        , driving conversations around DEI, and Waybridge&apos;s place in the
        global community. <br />
        <br />
        The joy of being together can&apos; t be measured in any amount of
        pounds or metric tons.
      </div>
    }
    link=""
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2021-11-19.jpg"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="3"
    date="2021-10-27"
    title="To Security and Beyond"
    text="Our team has been hard at work building strong policies, procedures, and systems to earn a System and Organization Controls Type 2 certification, also known as SOC 2. It is a challenging standard to meet, and we’re proud of the impact and trust great security can create."
    link="/press-releases/soc-2"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2021-10-27.png"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="2"
    date="2021-07-02"
    title="Waybridge featured on TechBlitz"
    text={
      <div>
        We are thankful to TechBlitz in Japan for the feature on Waybridge.
        Co-founder Scott Evans shared with the publication our vision and
        challenge in aiming to transform raw material supply chains.
        <br />
        <br />
        Click above to read the article in Japanese.
      </div>
    }
    link=""
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2021-07-02.jpg"
        alt=""
      />
    }
  />,
  <BlogEntry
    key="1"
    date="2021-06-01"
    title="Scott Evans interviewed on The Logistics of Logistics Podcast"
    text={
      <div>
        Our co-founder Scott Evans joined Joe Lynch at The Logistics of
        Logistics for a thoughtful conversation about making the raw materials
        supply chain more efficient, resilient, and sustainable.
        <br />
        <br />
        Listen to the interview (37 min){" "}
        <a
          className="underline"
          href="https://www.thelogisticsoflogistics.com/buying-better-and-reducing-risk-with-scott-evans/"
        >
          here
        </a>
        .<br />
        <br />
        Thank you, Joe, for having us.
      </div>
    }
    link="https://www.thelogisticsoflogistics.com/buying-better-and-reducing-risk-with-scott-evans/"
    image={
      <StaticImage
        imgStyle={{ borderRadius: "0.25rem" }}
        src="../images/blog/2021-06-01.png"
        alt=""
      />
    }
  />,
];

BlogEntry.propTypes = {
  link: PropTypes.string,
  image: PropTypes.object,
  date: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

const IndexPage = () => {
  return (
    <>
      <Helmet title="Home" />
      <Container className="bg-wbBlue py-12">
        <MinehubBanner />
        <div className="px-6 col-span-30 md:col-span-30">
          <div className="pt-32 mb-20 md:mb-40 xl:mb-60 text-white flex flex-col xl:flex-row gap-8 sm:gap-8">
            <A1 className="text-wbSky col-span-10">Waybridge:</A1>
            <A1 className="xl:pl-20 sm:max-w-2xl xl:max-w-5xl">
              Commodity supply chains, demystified.
            </A1>
          </div>

          <div className="xl:container rounded-3xl mb-20 md:mb-40 xl:mb-60 bg-cover bg-no-repeat bg-wbStem bg-1301 bg-fixed bg-top overflow-hidden mx-auto">
            <div className="flex flex-col md:flex-row items-center gap-4 my-8 md:my-24 px-4 md:pl-8 flex-wrap">
              <div className="flex-1">
                <StaticImage
                  className="bg-transparent max-w-[638px]"
                  imgClassName="animate-fade-in animate-blur-in bg-transparent"
                  src="../images/mockups/co2e_2x.png"
                  alt="CO2e"
                />
              </div>

              <div className="p-4 md:p-8 flex-1 text-left">
                <A1 className="text-white flex-1 pb-4">
                  Spotlight:
                  <br />
                  CO2e reports
                </A1>
                <div className="flex flex-col md:flex-row gap-4">
                  <Button
                    href="/platform"
                    className="block md:inline-block w-full md:w-auto"
                  >
                    View platform features
                  </Button>
                  <Button
                    href="/request-demo"
                    className="block md:inline-block w-full md:w-auto"
                  >
                    Book a demo
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="md:min-h-half col-span-30 grid grid-cols-30 gap-y-8 mb-20 text-white">
            <div className="col-span-30 md:col-span-15">
              <A1 className="mb-8">
                <div className="text-wbSea">Client</div>
                <div className="text-wbSand">Success story</div>
              </A1>

              <div className="md:ml-20">
                <Button
                  className="mx-auto md:mx-0"
                  href="/press-releases/superior-essex-case-study"
                >
                  Read the case study
                </Button>
              </div>
            </div>
            <div className="col-span-30 md:col-span-12 flex flex-col">
              <div className="border-solid border-white border-t-1 px-8 py-4 flex flex-row items-center gap-x-2">
                <span className="col-span-1 min-w-[3em] home-stat">90%</span>
                <span className="col-span-3">Reduced spreadsheet usage</span>
              </div>
              <div className="border-solid border-white border-t-1 px-8 py-4 flex flex-row items-center gap-x-2">
                <span className="col-span-1 min-w-[3em] home-stat">60%</span>
                <span className="col-span-3">
                  Reduced email usage around shipment tracking
                </span>
              </div>
              <div className="border-solid border-white border-t-1 px-8 py-4 flex flex-row items-center gap-x-2">
                <span className="col-span-1 min-w-[3em] home-stat">4</span>
                <span className="col-span-3">
                  Weeks ahead in ordering for all copper procurement
                </span>
              </div>
              <div className="border-solid border-white border-t-1 border-b-1 px-8 py-4 flex flex-row items-center gap-x-2">
                <span className="col-span-1 min-w-[3em] home-stat">5</span>
                <span className="col-span-3">Stock outs avoided</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-30">
          <A1 className="leading-none text-wbSea px-8 pb-8">Latest News</A1>
          <div className="xl:container ml-auto xl:mx-auto md:mb-20 px-3">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 768: 2, 1024: 3 }}
            >
              <Masonry>
                {BlogEntries.map((entry, i) => (
                  <div key={i}>{entry}</div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
        <div className="" />
        {/* END CONTAINERS */}
      </Container>
    </>
  );
};
export default IndexPage;
