import React from "react";

const MinehubBanner = () => {
  // const [visible, setVisible] = React.useState(true)
  // if (!visible) return null;
  return (
    <div
      className="bg-white col-span-full p-2 md:py-8 md:p-4 font-bold border-b-1 border-blue-300 backdrop-blur-md backdrop-grayscale"
      style={{ gridColumn: "1 / -1" }}
    >
      <h2 className="text-lg mb-2">Waybridge is thrilled to join MineHub!</h2>
      <div className="text-gray-600">
        <p>
          Together, we&lsquo;ll revolutionize the digital transformation of
          commodity supply chains, making them more efficient, resilient and
          sustainable.
        </p>
        <p>
          Read our official press release{" "}
          <a
            href="https://minehub.com/minehub-and-waybridge-combine-to-create-the-leading-digital-platform-for-commodity-supply-chains/"
            rel="noreferrer"
            target="_blank"
            className="text-blue-500"
          >
            here
          </a>
          .
        </p>
      </div>
      {/* <a href="#" onClick={(e) => {
		e.preventDefault();
		setVisible(false);
	}}>close [x]</a> */}
    </div>
  );
};

export default MinehubBanner;
